import './product-highlight-teaser.scss';

import Swiper, { Navigation, Pagination } from 'swiper';

class ProductHighlightTeaser {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-product-highlight-teaser',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$productHighlightTeaser = $element;
        this.$productHighlightTeaserContainer = this.$productHighlightTeaser.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$productHighlightTeaserSlides = this.$productHighlightTeaser.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.productHighlightSlider = null;

        if (this.$productHighlightTeaserContainer && this.$productHighlightTeaserSlides.length > 1) {
            this.initialize();
        }
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.initSlider();
    }

    initSlider () {
        this.productHighlightSlider = new Swiper(this.$productHighlightTeaserContainer, {
            modules: [Navigation, Pagination],
            speed: 500,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            slidesPerView: 'auto',
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }

    destroySlider () {
        if (this.productHighlightSlider !== null) {
            this.productHighlightSlider.destroy(true, true);
            this.productHighlightSlider = null;
        }
    }
}

export { ProductHighlightTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$productHighlightTeaser = $context.querySelectorAll('[data-product-highlight-teaser="root"]');
        for (let i = 0; i < $$productHighlightTeaser.length; i++) {
            const $productHighlightTeaser = $$productHighlightTeaser[i];

            $productHighlightTeaser.API = new ProductHighlightTeaser($productHighlightTeaser);
        }
    }
});
